import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import Features from "../components/Features";
import BlogRoll from "../components/BlogRoll";
import FullWidthImage from "../components/FullWidthImage";
// import "../components/styles/milonga_styles.js";
import { GatsbyImage } from "gatsby-plugin-image";

const milonga_styles = {
  floatContainer: {
      display: "flex",
      // border: "3px solid #fff",
      padding: "20px",
      height: "100%",
  },
  floatChild: {
      minHeight: "100%",
      height: "100%",
      width: "50%",
      float: "left",
      padding: "20px",
      // border: "2px solid red",
  },
}

// eslint-disable-next-line
export const MilongaPageTemplate = ({
  image,
  title,
}) => {
  const heroImage = getImage(image) || image;

  return (
    <div class="float-container" style={milonga_styles.floatContainer}>

      <div class="float-child" style={milonga_styles.floatChild}>
        <div>
        <h1 style={{textAlign: "center", fontSize: "40px", paddingBottom:"20px"}}>
        BACK TO DABNEY!
        </h1>
        <h2 style={{textAlign: "left", fontSize: "30px"}}>
          When: Friday, 11/18/2022</h2>
        <h2 style={{textAlign: "left", fontSize: "30px"}}>
          Where: Dabney Hall, Caltech Campus</h2>
        <a href="https://www.google.com/maps?daddr=34.137332940043166,-118.12525428999834" target="_blank">
          Link to Google Maps</a>
        </div>
      </div>

      <div class="float-child" style={milonga_styles.floatChild}>
        <GatsbyImage
            image={heroImage}
            objectFit={"contain"}
            objectPosition={"top right"}
            layout="fullWidth"
            // You can optionally force an aspect ratio for the generated image
            aspectratio={3 / 1}
            // This is a presentational image, so the alt should be an empty string
            alt=""
            formats={["auto", "webp", "avif"]}
        />
      </div>
      
      
      
    </div>
  );
};

MilongaPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
};

const MilongaPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <MilongaPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
      />
    </Layout>
  );
};

MilongaPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default MilongaPage;

export const pageQuery = graphql`
  query MilongaPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "milonga-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;